<template>
  <div
    v-if="
      getUser.role.slug === 'super-administrateur' ||
      getUser.role.slug === 'rh' ||
      getUser.role.slug === 'assistant-rh'
    "
  >

  <div v-if="isError">
  <b-breadcrumb class="mb-2">
    <b-breadcrumb-item to="/work/providers/recommandations/business">Packages</b-breadcrumb-item>
    <b-breadcrumb-item active>Détails</b-breadcrumb-item>
  </b-breadcrumb>
</div>
<div v-else>
  <b-breadcrumb class="mb-2">
    <b-breadcrumb-item to="/work/providers/recommandations/business">Packages</b-breadcrumb-item>
    <b-breadcrumb-item>
      <router-link
        :to="{
          name: 'business-orders',
          params: { id: this.$route.query.packageId } 
         
        }"
        >Commandes</router-link
      >
    </b-breadcrumb-item>
    <b-breadcrumb-item active>Détails</b-breadcrumb-item>
  </b-breadcrumb>
</div>
    
    <div
      v-if="isPageLoading"
      class="d-flex justify-content-center mb-1"
      style="padding-top: 30vh"
    >
      <b-spinner variant="info" style="width: 3rem; height: 3rem" class="mr-1" />
    </div>
    <div
      v-else-if="isError"
      class="d-flex flex-column justify-content-center mt-5 text-center"
      style="padding-top: 30vh"
    >
      <h1 class="text-danger">
        <feather-icon icon="XCircleIcon" size="100" />
      </h1>
      <p class="font-medium-1 text-danger font-weight-normal">
        Aucune commande ne correspond à cet identifiant
      </p>
    </div>
    <div v-else>
      <template>
        <div>
          <b-row class="invoice-preview">
            <!-- Col: Left (Invoice Container) -->
            <b-col cols="12" xl="8" md="8">
              <b-card no-body class="invoice-preview-card">
                <!-- Header -->
                <b-card-body class="invoice-padding pb-0">
                  <div
                    class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
                  >
                    <!-- Header: Left Content -->
                    <div>
                      <div class="logo-wrapper">
                        
                      <b-avatar
                        :text="
                          avatarText(
                            `${directOrder.package.user.first_name} ` +
                              ` ${directOrder.package.user.last_name}`
                          )
                        "
                        :src="directOrder.package.user.profile_image"
                        size="104px"
                        rounded
                      />
                    </div>
                    <h3 class="mt-2 text-success mb-25">
                      {{ directOrder.package.user.full_name }}
                    </h3>
                  </div>

                    <!-- Header: Right Content -->
                    <div class="mt-md-0 mt-2">
                      <h4 class="invoice-title">
                        Commande
                        <span class="invoice-number text-success">
                          #{{ directOrder.id }}
                        </span>
                        <b-badge
                          pill
                          :variant="`light-${resolveOrderStatusVariant(
                            directOrder.status
                          )}`"
                          class="text-capitalize font-medium-3"
                        >
                          {{ directOrder.setStatus }}
                        </b-badge>
                      </h4>
                      <div class="invoice-date-wrapper" v-if="directOrder.package.user.is_company == true">
                      <p class="invoice-date-title">Entreprise:</p>
                      <small class="font-weight-bold text-info font-small-4">
                        {{ directOrder.package.user.company_name }}
                      </small>
                    </div>
                    <div class="invoice-date-wrapper">
                      <p class="invoice-date-title">IFU:</p>
                      <small class="font-weight-bold text-info font-small-4">
                        {{ directOrder.package.user.company_ifu == null ? 'Non renseigné': directOrder.package.user.company_ifu}}
                      </small>
                    </div>
                      <div class="invoice-date-wrapper">
                        <p class="invoice-date-title">Téléphone:</p>
                        <small class="font-weight-bold text-info font-small-4">
                          {{ directOrder.package.user.phone_number }}
                        </small>
                      </div>
                      <div class="invoice-date-wrapper">
                        <p class="invoice-date-title">Email:</p>
                        <small class="font-weight-bold text-info font-small-4">
                          {{ directOrder.package.user.email ==null ? 'Non renseigné' : directOrder.package.user.email}}
                        </small>
                      </div>
                      <div class="invoice-date-wrapper">
                        <p class="invoice-date-title">Quartier:</p>
                        <small class="font-weight-bold text-info font-small-4">
                          {{ directOrder.address}}
                        </small>
                      </div>
                    </div>
                  </div>
                </b-card-body>

                <!-- Spacer -->
                <hr class="invoice-spacing" />

                <!-- Invoice Client & Payment Details -->
                <b-card-body class="pt-0">
                  <h6 class="mb-2 text-center font-medium-2">Détails de la commande</h6>
                  <b-row class="">
                    <!-- Col: Invoice To -->
                    <b-col md="6" cols="12">
                     
                      <p class="card-text mb-25 font-medium-1">
                        Budget par employé:
                        <span class="text-info">
                          {{ directOrder.brut_salary }} FCFA
                        </span>
                      </p>
                      <p class="card-text mb-25 font-medium-1">
                        Service demandé:
                        <span class="text-info">{{
                          directOrder.recurring_service.name
                        }}</span>
                      </p>
                      <p class="card-text mb-25 font-medium-1">
                        Date de la demande:
                        <span class="text-info">
                          {{ moment(directOrder.created_at).locale("fr").format("llll") }}
                        </span>
                      </p>
                    </b-col>
                    <b-col md="6" cols="12">
                      <p class="card-text mb-25 font-medium-1">
                        Fréquence d'intervention:
                        <span class="text-info">
                          {{ directOrder.intervention_frequency }} fois par semaine
                        </span>
                      </p>
                      <p class="card-text mb-25 font-medium-1">
                        Nombre d'employé:
                        <span class="text-info">
                          {{ directOrder.number_of_employees }}
                        </span>
                      </p>
                      
                      <p class="card-text mb-25 font-medium-1">
                        CNSS:
                        <span class="text-info">
                          {{ directOrder.cnss == true ? "Oui" : "Non" }}
                        </span>
                      </p>
                    </b-col>
                    <!-- Col: Payment Details -->
                  </b-row>
                </b-card-body>

                <!-- Invoice Description: Table -->
                
                <!-- <b-table-lite
                  responsive
                  :items="[{ feedback: directOrder.rh_feedback }]"
                  :fields="[
                    {
                      key: 'feedbackRh',
                      label: 'Feedback du RH',
                      sortable: true,
                      class: 'text-center font-medium-2',
                    },
                  ]"
                >
                  <template #cell(feedback)="data">
                    <b-card-text class="font-weight-bold mb-25 font-medium-1 text-sucess">
                      {{
                        directOrder.rh_feedback !== null
                          ? directOrder.rh_feedback
                          : "Feedback non effectué pour cette commande"
                      }}
                    </b-card-text>
                  </template>
                </b-table-lite> -->
                
               
              </b-card>
            </b-col>
            <!-- Right Col: Card -->
            <b-col
              v-if="
                getUser.role.slug === 'super-administrateur' ||
                getUser.role.slug === 'rh' ||
                getUser.role.slug === 'assistant-rh'
              "
              cols="12"
              md="4"
              xl="4"
              class="invoice-actions"
            >
              <b-card>

               
                <!-- Button: Send Invoice -->
                <b-button
                  @click="makeAffectation(directOrder)"
                  variant="primary"
                  class="mb-75 font-weight-normal font-medium-1"
                  block
                  :disabled="
                    directOrder.placement_cost_paid == false && directOrder.search_employee == true ||
                    directOrder.feedback == ''
                  "
                >
                  Affecter 
                </b-button>
                <!-- List recommandations -->
                <b-button
                  v-b-modal.modal-oder-have-not-proposal-recommended-employee
                  variant="outline-info"
                  class="mb-75 font-weight-normal font-medium-1"
                  block
                  @click="applyGetDirectOrderRecommendedEmployeesAction"
                  
                >
                  Liste des affectations
                </b-button>
               
                
              </b-card>
            </b-col>
          </b-row>
        </div>
        <!-- Sending Feedback Modal -->
        <b-modal
          id="modal-send-feedback"
          cancel-variant="outline-secondary"
          hide-footer
          centered
          no-close-on-backdrop
          no-close-on-esc
          title="Feedback "
        >
          <validation-observer #default="{}" ref="orderAssignmentForm">
            <!-- Form -->
            <b-form
              class="p-2"
              autocomplete="off"
              @reset.prevent="hideModalSendFeedback()"
              @submit.prevent="applySendFeedbackActionRH()"
            >
              <b-form-group
                label="Résumé de ce que vous pensez par rapport à l'employé(s) recommandé(s) "
                label-for="textarea-default"
              >
                <validation-provider
                  #default="{ errors }"
                  name="feedback"
                  rules="required"
                >
                  <b-form-textarea
                    id="textarea-default"
                    v-model="payload.rh_feedback"
                    :state="errors.length > 0 ? false : null"
                    placeholder=""
                    rows="3"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Form Actions -->
              <div class="d-flex mt-2">
                <b-button
                  :disabled="isFeedbackSending"
                  variant="primary"
                  class="mr-2"
                  type="submit"
                >
                  <div v-if="isFeedbackSending">
                    <span> Chargement ... </span>
                    <b-spinner small />
                  </div>
                  <span v-else>Soumettre</span>
                </b-button>
                <b-button
                  type="reset"
                  variant="outline-secondary"
                  @click="hideModalSendFeedback()"
                >
                  Annuler
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </b-modal>
        <!-- LISTE DES RECOMMANDATIONS -->
        <b-modal
          id="modal-oder-have-not-proposal-recommended-employee"
          cancel-variant="outline-secondary"
          hide-footer
          centered
          no-close-on-backdrop
          no-close-on-esc
          title="Employés affectés"
          size="xl"
        >
          <div
            v-if="isLoadingEmployeesRecommended"
            class="d-flex justify-content-center mb-1"
          >
            <b-spinner class="text-info" small />
          </div>
          <b-table
            v-else
            responsive
            class="position-relative"
            :items="items"
            show-empty
            empty-text="Cette commande n'a aucune recommandation d'employé"
            :fields="[
              {
                key: 'employee',
                label: 'PHOTO',
                sortable: true,
                class: 'text-center font-medium-2',
              },
              {
                key: 'employee_full_name',
                label: 'NOM & PRÉNOM ',
                sortable: true,
                class: 'text-center font-medium-2 text',
              },
              {
                key: 'age',
                label: 'AGE',
                sortable: true,
                class: 'text-center font-medium-2',
              },
              {
                key: 'salaire',
                label: 'SALAIRE',
                sortable: true,
                class: 'text-center font-medium-2',
              },
              
              {
                key: 'actions',
                label: 'ACTIONS',
                sortable: true,
                class: 'text-center font-medium-2',
              },
            ]"
          >
            <template #cell(employee)="data">
              <b-avatar :src="data.item.employee.profile_image" size="45" />
            </template>
            <template #cell(employee_full_name)="data">
              <span class="text-info">{{ data.item.employee.full_name }}</span>
            </template>

            <template #cell(age)="data">
              <span class="text-info">{{ data.item.employee.age }} ans </span>
            </template>
            <template #cell(salaire)="data">
              <span class="text-info">{{ data.item.employee_salary }} FCFA </span>
            </template>
            
            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <div class="text-nowrap">
                <feather-icon
                  :id="`feedback${data.item.id}`"
                  icon="EditIcon"
                  class="cursor-pointer mr-1"
                  size="25"
                  @click="
                    applyGetServiceEmployeesAction(directOrder),
                      showUpdateRecommandationModal(data.item)
                  "
                />
                <feather-icon
                  :id="`feedback${data.item.id}`"
                  icon="TrashIcon"
                  class="cursor-pointer mr-1"
                  size="25"
                  color="red"
                  @click="applyDeleteEmployeeAffectation(data.item)"
                />
                <feather-icon
                  :id="`feedback${data.item.id}`"
                  icon="FileIcon"
                  class="cursor-pointer mr-1"
                  size="25"
                  color="green"
                  @click="
                    openBusinessEmployeeContract(data.item)
                  "
                />
              </div>
            </template>
          </b-table>
        </b-modal>
        <!-- Update recommandation -->
        <b-modal
          id="modal-update-order-have-not-recommandation"
          cancel-variant="outline-secondary"
          hide-footer
          centered
          no-close-on-backdrop
          no-close-on-esc
          title="Modification de l'affectation"
        >
          <validation-observer #default="{}" ref="orderAssignmentForm">
            <!-- Form -->
            <b-form
              class="p-2"
              autocomplete="off"
              @reset.prevent="hideModalRecommandation()"
              @submit.prevent="applyUpdateAffectationAction()"
            >
              <b-form-group label="Choisissez un employé" label-for="role">
                <validation-provider #default="{ errors }" name="pro" rules="required">
                  <b-form-select
                    v-if="employeeOptions.length === 1 && employeeOptions[0].disabled"
                    id="employee_id"
                    v-model="updatePayload.employee_id"
                    :options="employeeOptions"
                  />
                  <v-select
                    v-else
                    id="role"
                    v-model="updatePayload.employee_id"
                    :reduce="(employee) => employee.id"
                    label="full_name"
                    :options="employeeOptions"
                  >
                    <span slot="no-options" @click="$refs.select.open = false">
                      Aucun employé ne correspond à ce nom
                    </span>
                    <template
                      #option="{
                        profile_image,
                        full_name,
                        address,
                        age,
                        marital_status,
                        nationality,
                        degree,
                        mtn_number,
                        flooz_number,
                      }"
                    >
                      <b-row>
                        <b-col md="3" class="offset-md-3">
                          <b-avatar size="90px" rounded :src="profile_image" />
                        </b-col>
                      </b-row>
                      <br />
                      <b-row>
                        <b-col md="9">
                          <b-row>
                            <b-col>
                              <span class="text-white">
                                Nom & Prénom:
                                <strong class="text-info"> {{ full_name }} </strong>
                              </span>
                            </b-col>
                            <br />
                            <b-col>
                              <span class="text-white">
                                Age:
                                <strong class="text-info"> {{ age }} ans </strong>
                              </span>
                            </b-col>
                            <br />
                          </b-row>

                          <b-row class="">
                            <b-col>
                              <span class="text-white">
                                Situation matrimonial:<strong class="text-info">
                                  {{ marital_status }}
                                </strong>
                              </span>
                            </b-col>
                            <br />
                            <b-col>
                              <span class="text-white">
                                Quartier:
                                <strong class="text-info"> {{ address }}</strong>
                              </span>
                            </b-col>
                            <br />
                          </b-row>
                          <b-row class="">
                            <b-col>
                              <span class="text-white">
                                Nationnalité:
                                <strong class="text-info">
                                  {{ nationality }}
                                </strong>
                              </span>
                            </b-col>
                            <br />
                            <b-col>
                              <span class="text-white">
                                Diplôme:
                                <strong class="text-info"> {{ degree }}</strong>
                              </span>
                            </b-col>
                          </b-row>
                          <b-row>
                            <br />
                            <b-col>
                              <span class="text-white">
                                Numéro MTN momo :
                                <strong class="text-info">
                                  {{ !mtn_number ? "Non renseigné" : mtn_number }}
                                </strong>
                              </span>
                            </b-col>
                            <br />
                            <b-col>
                              <span class="text-white">
                                Numéro Flooz momo :
                                <strong class="text-info">
                                  {{ !flooz_number ? "Non renseigné" : flooz_number }}
                                  {{ flooz_number }}
                                </strong>
                              </span>
                            </b-col>
                            <br />
                            <b-col>
                              <span class="text-white">
                                Diplôme:
                                <strong class="text-info">{{ degree }}</strong>
                              </span>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <hr />
                      <br />
                    </template>
                  </v-select>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Salaire de l'employé" label-for="textarea-default">
                <validation-provider
                  #default="{ errors }"
                  name="description"
                  rules="required"
                >
                  <b-form-input
                    id="textarea-default"
                    v-model="updatePayload.employee_salary"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Salaire de l'employé"
                    rows="3"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Form Actions -->
              <div class="d-flex mt-2">
                <b-button
                  :disabled="isOrderAssignmentLoading"
                  variant="primary"
                  class="mr-2"
                  type="submit"
                >
                  <div v-if="isOrderAssignmentLoading">
                    <span> Chargement ... </span>
                    <b-spinner small />
                  </div>
                  <span v-else>Soumettre</span>
                </b-button>
                <b-button
                  type="reset"
                  variant="outline-secondary"
                  @click="hideModalRecommandation()"
                >
                  Annuler
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </b-modal>

        <!-- List Desistement Modal -->
        <b-modal
          id="modal-terminated-employee"
          cancel-variant="outline-secondary"
          hide-footer
          centered
          no-close-on-backdrop
          no-close-on-esc
          title="Désistements"
        >
          <b-table
            ref="refInvoiceListTable"
            :items="listOfTerminatedEmployees"
            responsive
            :fields="terminatedEmployeesColumns"
            primary-key="id"
            :busy="isTerminatedEmployees"
            show-empty
            empty-text="Liste vide"
            class="position-relative"
          >
            <template #table-busy>
              <div class="text-center text-info my-2">
                <b-spinner class="align-middle" /> &nbsp;
                <strong>Chargement...</strong>
              </div>
            </template>

            <!-- Column: Client -->
            <template #cell(profile_image)="data">
              <b-media vertical-align="center">
                <template #aside>
                  <b-avatar
                    rounded
                    :text="avatarText(data.item.recommandation.employee.full_name)"
                    size="42"
                    :src="data.item.employee.profile_image"
                  />
                </template>
                <span class="font-weight-bold d-block text-nowrap">
                  {{ data.item.recommandation.employee.full_name }}
                </span>
                <small class="text-success font-small-3">
                  {{ data.item.recommandation.employee.phone_number }}</small
                >
              </b-media>
            </template>

            <!-- Column: Employee contract started date -->
            <template #cell(employee_contract_started_date)="data">
              <span>
                {{
                  moment(data.item.recommandation.employee_contract_started_date)
                    .locale("fr")
                    .format("dddd DD MMMM YYYY")
                }}
              </span>
            </template>

            <template #cell(reason)="data">
              <span class="text-info">
                {{ data.item.reason != null ? data.item.reason : "Non renseignée" }}
              </span>
            </template>

            <!-- Column: Employee contract startended date -->
            <template #cell(employee_contract_end_date)="data">
              <span>
                {{
                  moment(data.item.recommandation.employee_contract_end_date)
                    .locale("fr")
                    .format("dddd DD MMMM YYYY")
                }}
              </span>
            </template>
          </b-table>
        </b-modal>
      </template>
    </div>
    <b-modal
      id="modal-archivage"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      no-close-on-backdrop
      no-close-on-esc
      title="Raison de l'archivage"
    >
      <validation-observer #default="{}" ref="archivingOrderForm">
        <!-- Form -->
        <b-form
          class="p-2"
          autocomplete="off"
          @reset.prevent="hideModal"
          @submit.prevent="applyArchiveDirectOrderAction()"
        >
          <validation-provider #default="{ errors }" name="archivage" rules="required">
            <b-form-group
              label="Raison de l'archivage"
              label-for="archivage"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-textarea
                id="archiving-reason"
                v-model="archivingPayload.archiving_reason"
                placeholder="Laissez la raison de l'archivage de cette commande"
                rows="3"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              :disabled="isArchivingLoading"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <div v-if="isArchivingLoading">
                <span>Chargement ... </span>
                <b-spinner small />
              </div>
              <span v-else>Archiver</span>
            </b-button>
            <b-button
              type="reset"
              variant="outline-secondary"
              @click="$bvModal.hide('modal-archivage')"
            >
              Annuler
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
      <!-- List Proposition Modal -->
        <b-modal
          id="modal-proposed-employee"
          cancel-variant="outline-secondary"
          hide-footer
          centered
          no-close-on-backdrop
          no-close-on-esc
          title="Propositions effectuées"
          size="xl"
          stacking
        >
          <b-table
            ref="refInvoiceListTable"
            :items="propositions"
            responsive
            :fields="tableColumns1"
            primary-key="id"
            :busy="isProposedEmployeesLoading"
            show-empty
            empty-text="Liste vide"
            class="position-relative"
          >
            <template #table-busy>
              <div class="text-center text-info my-2">
                <b-spinner class="align-middle" /> &nbsp;
                <strong>Chargement...</strong>
              </div>
            </template>
            <!-- Column: Invoice Status -->
            <template #cell(is_rejected)="data">
              <b-badge
                pill
                :variant="`light-${resolvePropositionStatusVariant(data.item.status)}`"
                class="text-capitalize font-small-3"
              >
                {{ resolveStatusPropositionText(data.item.status) }}
              </b-badge>
            </template>
            <!-- Salaire -->
            <template #cell(salary)="data">
              <span> {{ data.item.recommandation.employee_salary }} FCFA </span>
            </template>
            <!-- Salaire -->
            <template #cell(comment)="data">
              <span>
                {{ data.item.recommandation.comment }}
              </span>
            </template>

            <!-- Column: employee -->
            <template #cell(employee)="data">
              <b-media vertical-align="center">
                <template #aside>
                  <b-avatar
                    size="32"
                    :src="data.item.recommandation.employee.profile_image"
                  />
                </template>
                <span class="font-weight-bold d-block text-nowrap">
                  {{ data.item.recommandation.employee.full_name }}
                </span>
                <small class="text-success font-small-3">{{
                  data.item.recommandation.employee.phone_number
                }}</small>
              </b-media>
            </template>
            <!-- Column: Employee contract started date -->
            <template #cell(employee_contrat_started_date)="data">
              <span v-if="data.item.status === 2">
                {{
                  moment(data.item.employee_contrat_started_date)
                    .locale("fr")
                    .format("dddd DD MMMM YYYY")
                }}
              </span>
              <span v-else-if="data.item.status === -2">
                {{
                  moment(data.item.employee_contrat_started_date)
                    .locale("fr")
                    .format("dddd DD MMMM YYYY")
                }}
              </span>
              <span v-else-if="data.item.status === 1"> En attente de déploiement</span>
              <span v-else> En attente d'acceptation </span>
            </template>

            <template #cell(end_contrat_reason)="data">
              <span class="text-info">
                {{
                  data.item.end_contrat_reason != null
                    ? data.item.end_contrat_reason
                    : "--------"
                }}
              </span>
            </template>

            <template #cell(rejection_reason)="data">
              <span class="text-info">
                {{
                  data.item.rejection_reason != null
                    ? data.item.rejection_reason
                    : "--------"
                }}
              </span>
            </template>

            <!-- Column: Employee contract ended date -->
            <template #cell(employee_contrat_end_date)="data">
              <span v-if="data.item.status === -2">
                {{
                  moment(data.item.employee_contrat_end_date)
                    .locale("fr")
                    .format("dddd DD MMMM YYYY")
                }}
              </span>
              <span v-else-if="data.item.status === 2"> Contrat actif </span>
              <span v-else-if="data.item.status === 1"> En attente de déploiement </span>
              <span v-else> En attente d'acceptation </span>
            </template>
            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <div class="text-nowrap">
                <b-dropdown variant="link" toggle-class="p-0" no-caret>
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="25"
                      class="align-middle text-center text-info"
                    />
                  </template>
                  <b-dropdown-item
                   v-if="
                      getUser.role.slug === 'super-administrateur' ||
                      getUser.role.slug === 'rh' ||
                      getUser.role.slug === 'assistant-rh'
                    "
                  
                    :id="`open${data.item.id}`"
                    class="cursor-pointer itemActions"
                    @click="openEmployeeContract(data.item)"
                      :disabled="
                      data.item.status === -2 ||
                       data.item.status == 0
                    "
                  >
                    <feather-icon icon="FileTextIcon" class="text-success" size="20" />
                    <span class="align-middle ml-50">Contrat employé</span>
                  </b-dropdown-item>
                  
                </b-dropdown>
              </div>
             
            </template>
          </b-table>
        </b-modal>
  </div>
  <div v-else>
    <not-authorized />
  </div>
</template>

<script>
import {
  BTab,
  BTabs,
  BAlert,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BForm,
  BFormTextarea,
  BFormGroup,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BTableLite,
  BDropdown,
  BDropdownItem,
  BCardHeader,
  BCardBody,
  BFormSelect,
  BSpinner,
  BCardText,
  // BPagination,
  BTooltip,
  BBreadcrumb,
  BBreadcrumbItem,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum } from "@validations";
import { mapActions, mapGetters } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";

export default {
  components: {
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    BTab,
    BTableLite,
    BFormTextarea,
    // eslint-disable-next-line vue/no-unused-components
    BTabs,
    BFormSelect,
    BSpinner,
    BForm,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    BCardHeader,
    BCardBody,
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BAlert,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,

    BLink,
    BBadge,

    BDropdown,

    BDropdownItem,
    // BPagination,
    BTooltip,
    vSelect,
    flatPickr,
    FeatherIcon,
    BBreadcrumb,
    BBreadcrumbItem,
  },

  data() {
    return {
      userPackage: null,
      isGetServiceEmployeesActionLoading: true,
      employeeOptions: [],
      rowsDirectOrdersHaveNotProposal: [],
      columns: [
        {
          label: "Client",
          field: "user",
          filterOptions: {
            enabled: true,
            placeholder: "Rechercher...",
            filterFn(data, filterString) {
              return data.full_name.toLowerCase().includes(filterString.toLowerCase());
            },
          },
        },
        {
          label: "Service demandé",
          field: "recurring_service",
          filterOptions: {
            enabled: true,
            placeholder: "Rechercher...",
            filterFn(data, filterString) {
              return data.name.toLowerCase().includes(filterString.toLowerCase());
            },
          },
        },
        {
          label: "Budget",
          field: "budget",
        },
        {
          label: "Lieu",
          field: "address",
          filterOptions: {
            enabled: true,
            placeholder: "Rechercher...",
            filterFn(data, filterString) {
              return data.toLowerCase().includes(filterString.toLowerCase());
            },
          },
        },
        {
          label: "Traitée par",
          field: "assign_to",
          filterOptions: {
            enabled: true,
            placeholder: "Rechercher...",
            filterFn(data, filterString) {
              return data.full_name.toLowerCase().includes(filterString.toLowerCase());
            },
          },
        },
        {
          label: "Action",
          field: "action",
        },
      ],

      isOrderAssignmentLoading: false,

      payload: {
        recommandations: [],
        recurring_order_id: this.$route.params.id,
      },
      employeeAffectedId: "",
      updatePayload: {
        employee_id: "",
        employee_salary: "",
      },

      items: [],
      currentOrder: {},
      isLoadingEmployeesRecommended: false,
      total: "",

      // Fin recommandations

      directOrder: null,
      required,
      propositions: [],
      serviceEmployees: [],
      selectEmployee: [],
      managedEmployee: [],
      alphaNum,
      isFeedbackSending: false,
      isMakingPropositionLoading: false,
      isProposedEmployeesLoading: false,
      isEmployeesServicesLoading: false,
      isArchivingLoading: false,
      // payload: {
      //   propositions: [],
      //   recurring_order_id : this.$route.params.id
      // },
      archivingPayload: {
        archiving_reason: "",
      },
      employee_amount: null,
      changingEmployee: {
        percentage: "",
        salary: "",
        employee_id: "",
        employee_amount: "",
      },
      customerBudget: 0,
      currentProposition: {},
      tableColumns1: [
        {
          key: "employee",
          label: "Employé",
          sortable: true,
          class: "font-medium-1",
        },
        {
          key: "salary",
          label: "Salaire net",
          sortable: true,
          class: "font-medium-1",
        },
        {
          key: "comment",
          label: "Commentaire",
          sortable: true,
          class: "font-medium-1",
        },
        {
          key: "employee_contrat_started_date",
          label: "Date de début du contrat",
          sortable: true,
          class: "font-medium-1",
        },
        {
          key: "employee_contrat_end_date",
          label: "Date de fin du contrat",
          sortable: true,
          class: "font-medium-1",
        },
        {
          key: "reason",
          label: "Raison",
          sortable: true,
          class: "font-medium-1",
        },

        {
          key: "is_rejected",
          label: "Statut",
          sortable: true,
          class: "font-medium-1",
        },
        { key: "actions", class: "font-medium-1" },
      ],
      avatarText,
      terminateEmployeePayload: {
        date: null,
        raison: "",
      },
      isTerminateEmployeeContratLoading: false,
      deployEmployeePayload: {
        employee_deployment_date: null,
      },
      isDeployEmployeeLoading: false,
      currentDate: null,
      directOrdersSalaryPaymentByOrder: [],
      employee_id: null,
      listOfTerminatedEmployees: [],
      isTerminatedEmployees: false,
      terminatedEmployeesColumns: [
        {
          key: "profile_image",
          label: "Identité",
          sortable: true,
          class: "font-small-3",
        },
        {
          key: "employee_contract_started_date",
          label: "Date de début du contrat",
          sortable: true,
          class: "font-small-3",
        },
        {
          key: "employee_contract_end_date",
          label: "Date de fin du contrat",
          sortable: true,
          class: "font-small-3",
        },
        {
          key: "reason",
          label: "Raison",
          sortable: true,
          class: "font-small-3",
        },
      ],
      isPageLoading: true,
      isError: false,
      errorMessage: "",
    };
  },

  computed: {
    ...mapGetters("auth", ["getUser"]),
    ...mapGetters("packages", ["getDirectOrder"]),
    ...mapGetters("orders", [
      // 'getDirectOrder',
      "getDirectOrdersSalaryPaymentByOrder",
      "getDirectOrdersHaveNotProposal",
      "getDirectOrdersWhoNeedRecommandation",
    ]),
    ...mapGetters(["isDirectOrdersWhoNeedRecommandationLoading"]),
    ...mapGetters("roles", ["getBusinessManagersList"]),

    metaDataHaveNotProposal() {
      const meta = {
        prev_page_url: "",
        next_page_url: "",
        current_page: "",
        last_page: "",
        current_page_url: "",
      };
      if (this.getDirectOrdersWhoNeedRecommandation != null) {
        meta.prev_page_url = this.getDirectOrdersWhoNeedRecommandation.prev_page_url;
        meta.next_page_url = this.getDirectOrdersWhoNeedRecommandation.next_page_url;
        meta.current_page = this.getDirectOrdersWhoNeedRecommandation.current_page;
        meta.last_page = this.getDirectOrdersWhoNeedRecommandation.last_page;
        meta.current_page_url = `${this.getDirectOrdersWhoNeedRecommandation.path}?page=${this.getDirectOrdersWhoNeedRecommandation.current_page}`;
      }
      return meta;
    },
  },

  watch: {
    isDirectOrdersWhoNeedRecommandationLoading(val) {
      if (val === false) {
        this.rowsDirectOrdersHaveNotProposal = this.getDirectOrdersHaveNotProposal;
        this.total = this.rowsDirectOrdersHaveNotProposal.length;
      }
    },

    selectedEmployee(val) {
      console.log(val);
      this.employee_amount = val.employee_salary;
    },
  },

  created() {
    this.applyGetBusinessOrder(this.$route.params.id);
    this.applyGetBusinessOrderAffectedEmployeesAction()
    this.getDirectOrdersHaveNotProposal != null
      ? (this.rowsDirectOrdersHaveNotProposal = this.getDirectOrdersHaveNotProposal)
      : null;
  },

  methods: {
    ...mapActions("services", ["getServiceEmployeesAction"]),
    ...mapActions("packages", [
      "getDirectOrderAction",
      "sendFeedbackAction",
      "makePropositionAction",
      "getRecommendedEmployeesAction",
      "getDirectOrderProposedEmployeesAction",
      "getRecommendationEmployeesAction",
      "deleteDirectOrderPropositionAction",
    ]),
    ...mapActions("orders", [
      "getBusinessOrderAction",
      "getAffectedEmployeesAction",
      "modifyAffectationAction",
      "makeEmployeeDeploymentAction",
      "terminateEmployeeContractAction",
      "markDirectOrderAsFinishedAction",
      "assignAnBusinessOrderToARHAction",
      "getBusinessOrdersAction",
      // 'deleteDirectOrderPropositionAction',
      // 'makePropositionAction',
      "deleteDirectOrderAction",
      "resetOrderAction",
      "archiveDirectOrderAction",
      // 'getDirectOrderAction',
      // 'getDirectOrderProposedEmployeesAction',
      "getDirectOrdersSalaryPaymentsByOrderAction",
      "getDirectOrderEmployeesServicesAction",
      // 'getRecommendedEmployeesAction',
      "getDirectOrdersAction",
      "modifyPropositionAction",
      // 'sendFeedbackAction',
      "getArchivedDirectOrdersAction",
      "getTerminatedEmployeesAction",

      "assignAnOrderToBusinessManagerAction",
      "loadMoreDirectOrdersWhoNeedRecommandationAction",
      "getDirectOrdersAssignedToBusinessManagerAction",
      "makeEmployeeRecommandationAction",
      
      // "getDirectOrderProposedEmployeesAction",
      "modifyRecommandationAction",
    ]),
    //RECOMMANDATION
    ...mapActions("professionals", ["deleteEmployeeAffectationAction"]),
    
    applyGetBusinessOrder(id) {
      this.getBusinessOrderAction(id)
        .then((response) => {
          this.isPageLoading = false;
          console.log("Orderssss", response.data);
          this.directOrder = response.data;
          this.applyGetBusinessOrderAffectedEmployeesAction();
          this.directOrder = this.resolveStatusText(this.directOrder);
          console.log("Orderssss", this.directOrder);
          this.directOrder = this.resolveStatusText(this.directOrder);
          // this.applyGetDirectOrdersSalaryPaymentByOrderAction(
          // this.directOrder.id,
          // )
        })
        .catch((error) => {
          console.log(error);
          this.isPageLoading = false;
          this.isError = true;
          this.errorMessage = Object.values(error.response.data)[0];
        });
      // }
    },

    reLoadDataRecurringOrdersAction() {
    this.isReLoadDataRecurringOrders = true
    this.$store.commit('SET_IS_BUSINESS_ORDERS_LOADING', true)
    this.rowOrders = []

    this.getBusinessOrdersAction()
      .then(response => {
        this.isReLoadDataRecurringOrders = false
        this.$store.commit('SET_IS_BUSINESS_ORDERS_LOADING', false)
      })
      .catch(error => {
        this.isReLoadDataRecurringOrders = false
        this.$store.commit('SET_IS_BUSINESS_ORDERS_LOADING', false)
      })
  },

    makeSelfAssignation(recurringOrderAssignation){
      this.autoAssignPayload ={
      rh_id: this.getUser.id
        }
    this.$swal
      .fire({
        title: "Auto assignation",
        text: "Êtes-vous sûr de vouloir gérer cette commande vous même ?",
        icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: "Non",
        allowOutsideClick: false,
        confirmButtonText: "Oui",
        customClass: {
          confirmButton: "btn btn-warning",
          cancelButton: "btn btn-outline-primary ml-1",
        },
        buttonsStyling: false,
        preConfirm: () =>
        this.assignAnBusinessOrderToARHAction({
          orderId: recurringOrderAssignation.id,
          payload: this.autoAssignPayload,
        })
        
          .then((response) => {

          })
          .catch((error) => {
            this.$swal.showValidationMessage(`${error.response.data.message}`);
          }),
      })
    .then(response => {
      console.log("Autoassignement", response)
      if (response.isConfirmed){
      this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Succès',
              icon: 'CheckIcon',
              text: 'Commande business assigné avec succès.',
              variant: 'success',
            },
          },
          {
            position: 'top-center',
          },
        )

        this.reLoadDataRecurringOrdersAction()
      }
      })
      .catch(error => {
        console.log(error)
        // this.$toast(
        //   {
        //     component: ToastificationContent,
        //     props: {
        //       title: 'Erreur',
        //       icon: 'AlertTriangleIcon',
        //       text: error.response.data.message,
        //       variant: 'danger',
        //     },
        //   },
        //   {
        //     position: 'top-center',
        //   },
        // )
      })


    },

    makeAffectation(recurringOrderId) {
      this.$router.push(
        `/affectation/make/${recurringOrderId.id}/service?packageId=${recurringOrderId.package.id}&recurringServiceId=${recurringOrderId.recurring_service_id}`
      );

    },

    openFeedback(item) {
      this.$router.push({
        path: "/preview/pdf",
        query: {
          makeActionOnPage: false,
          actionType: 'modifier',
          isUpdate: true,
          pdf: item.feedback,
          orderId: item.id,
          packageId: item.package_id,
          service: item.recurring_service_id
        },
      });
    },

    applyGetBusinessOrderAffectedEmployeesAction() {
      this.isLoadingEmployeesRecommended = true;
    
      this.getAffectedEmployeesAction(this.$route.params.id)
        .then((response) => {
          this.items = response.data;
          this.recommandations = response.data;
          console.log("Liste des recommandations", this.recommandations);
          this.isLoadingEmployeesRecommended = false;
        })
        .catch((error) => {
          this.isLoadingEmployeesRecommended = false;
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Erreur",
                icon: "AlertTriangleIcon",
                text: error,
                variant: "danger",
              },
            },
            {
              position: "top-center",
            }
          );
        });
    },

    applyUpdateAffectationAction() {
      this.isOrderAssignmentLoading = true;
      console.log(this.employeeRecommendationId)
      this.modifyAffectationAction({
        employeeAffectedId: this.employeeAffectedId,
        payload: this.updatePayload,
      })
        .then(async (response) => {
          console.log("ipdate", response);
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Succès",
                icon: "CheckIcon",
                text: response.data.message,
                variant: "success",
              },
            },
            {
              position: "top-center",
            }
          );

          this.isOrderAssignmentLoading = false;
          this.hideModalRecommandation();
          this.applyGetBusinessOrderAffectedEmployeesAction();
          this.$bvModal.show("modal-oder-have-not-proposal-recommended-employee");
        })
        .catch((error) => {
          this.isOrderAssignmentLoading = false;
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Erreur",
                icon: "AlertTriangleIcon",
                text: error.response.data.message,
                variant: "danger",
              },
            },
            {
              position: "top-center",
            }
          );
          this.updatePayload = {
            employee_salary: "",
            comment: "",
            employee_id: "",
            recurring_order_id: "",
          };
        });
    },

    applyDeleteEmployeeAffectation(item) {
      this.$swal
        .fire({
          title: "Annuler",
          text: "Êtes-vous sûr de vouloir supprimer cette assignation ?",
          icon: "warning",
          showCancelButton: true,
          showLoaderOnConfirm: true,
          cancelButtonText: "Non",
          allowOutsideClick: false,
          confirmButtonText: "Oui",
          customClass: {
            confirmButton: "btn btn-warning",
            cancelButton: "btn btn-outline-primary ml-1",
          },
          buttonsStyling: false,
          preConfirm: async () => {
            await this.deleteEmployeeAffectationAction({
              employeeAffectationId: item.id,
            })
              .then((response) => {})
              .catch((error) => {
                this.$swal.showValidationMessage(`${error.response.data.message}`);
              });
            // eslint-disable-next-line no-return-await
          },
        })
        .then((response) => {
          if (response.value) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "Succès",
                  icon: "CheckIcon",
                  text: "Assignation supprimée avec succès",
                  variant: "success",
                },
              },
              {
                position: "top-center",
              }
            );
            this.applyGetBusinessOrderAffectedEmployeesAction();
            this.$bvModal.show("modal-oder-have-not-proposal-recommended-employee");
            this.applyGetBusinessOrder(this.$route.params.id) 
            
            // this.applyLoadMoreDirectOrdersWhoNeedRecommandationAction(
            //   this.metaDataHaveNotProposal.current_page_url,
            // )
          }
        })
        .catch((error) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Erreur",
                icon: "AlertTriangleIcon",
                text: error,
                variant: "danger",
              },
            },
            {
              position: "top-center",
            }
          );
        });
    },
    
    applyLoadMoreDirectOrdersWhoNeedRecommandationAction(url) {
      this.$store.commit("SET_IS_DIRECT_ORDERS_WHO_NEED_RECOMMANDATION_LOADING", true);
      this.rowsDirectOrdersHaveNotProposal = [];
      this.loadMoreDirectOrdersWhoNeedRecommandationAction(url)

        .then(() => {
          this.$store.commit(
            "SET_IS_DIRECT_ORDERS_WHO_NEED_RECOMMANDATION_LOADING",
            false
          );
        })
        .catch(() => {
          this.$store.commit(
            "SET_IS_DIRECT_ORDERS_WHO_NEED_RECOMMANDATION_LOADING",
            false
          );
        });
    },
    
    showEmployeeRecommendedModal(data) {
      this.applyGetEmployeesRecommendedByOrder(data.id);
      this.$bvModal.show("modal-oder-have-not-proposal-recommended-employee");
      this.currentOrder = data;
      this.applyGetDirectOrderRecommendedEmployeesAction();
    },

    showUpdateRecommandationModal(item) {
      this.$bvModal.hide("modal-oder-have-not-proposal-recommended-employee");
      this.$bvModal.show("modal-update-order-have-not-recommandation");
      this.updatePayload.employee_salary = item.employee_salary;
      this.updatePayload.employee_id = item.employee_id;
      this.employeeAffectedId = item.id;
    },

    showFeedbackModal(item) {
      this.$bvModal.show("modal-oder-have-not-proposal-show-feedback");
      this.currentOrder = item;
      console.log(item);
    },

    
    

    applyGetServiceEmployeesAction(order) {
      this.isGetServiceEmployeesActionLoading = true;
      this.currentOrder = order;
      this.employeeOptions = [];
      this.getServiceEmployeesAction(order.recurring_service.id).then((response) => {
        this.isGetServiceEmployeesActionLoading = false;
        if (response.data.length === 0) {
          this.employeeOptions.push({
            value: "",
            disabled: true,
            text: "Pas d'employés formés qui fournissent ce service",
          });
        } else {
          response.data.forEach((element) => {
            if (element != null) {
              this.employeeOptions.push(element);
            }
          });
        }
      });
    },

   
    hideModal(data, modal) {
      data.employee_salary = "";
      data.employee_id = "";
      data.comment = "";
      recurring_order_id = "";
      this.$bvModal.hide(modal);
    },
    applyMakeEmployeeRecommandationAction() {
      if (this.taux < 15) {
        this.isOrderAssignmentLoading = false;
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              text: "La commission de YLOMI doit être supérieure ou égale à 15%",
              variant: "danger",
            },
          },
          {
            position: "top-center",
          }
        );
        return;
      }

      this.isOrderAssignmentLoading = true;
      this.payload.recommandations.push({
        employee_id: element.employee_id,
        employee_salary: element.employee_salary,
        comment: element.comment,
      });
      this.makeEmployeeRecommandationAction(this.payload)
        .then(async (response) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Succès",
                icon: "CheckIcon",
                text: response.data.message,
                variant: "success",
              },
            },
            {
              position: "top-center",
            }
          );
          this.isOrderAssignmentLoading = false;
          this.hideModal(this.payload, "modal-order-have-not-proposal-assignment");
          this.showEmployeeRecommendedModal(this.currentOrder);
          this.applyLoadMoreDirectOrdersWhoNeedRecommandationAction(
            this.metaDataHaveNotProposal.current_page_url
          );
        })
        .catch((error) => {
          this.isOrderAssignmentLoading = false;
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Erreur",
                icon: "AlertTriangleIcon",
                text: error.response.data.message,
                variant: "danger",
              },
            },
            {
              position: "top-center",
            }
          );
        });
    },
    // Recommandation
    archiveOrder(order) {
      if (order.status != 0) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Attention",
              icon: "AlertTriangleIcon",
              text: "Merci de réinitialiser cette commande avant de l'archiver",
              variant: "warning",
            },
          },
          {
            position: "top-center",
          }
        );
      } else {
        this.$bvModal.show("modal-archivage");
      }
    },
    ...mapActions("professionals", ["confirmPlacementCostPaidAction"]),

    /** ******************************Proposition************************************* */
    
   
    putRecurringOrdersInStoreAction(directOrder) {
      this.$store.commit("orders/SET_DIRECT_ORDER", this.resolveStatusText(directOrder));
      this.$router.push({
        name: "recommandation-orders-details",
        params: { id: directOrder.id },
        query: {packageId : this.$route.query.packageId}
      });
    },

    applyGetDirectOrderProposedEmployeesAction() {
      this.isProposedEmployeesLoading = true;
      const data = {
        recurring_order_id: this.directOrder.id,
      };
      this.getDirectOrderProposedEmployeesAction(data)
        .then((response) => {
          this.propositions = response.data;
          console.log(this.propositions);
          this.isProposedEmployeesLoading = false;
        })
        .catch((error) => {
          this.isProposedEmployeesLoading = false;
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Erreur",
                icon: "AlertTriangleIcon",
                text: error,
                variant: "danger",
              },
            },
            {
              position: "top-center",
            }
          );
        });
    },

    

    applyGetRecommendedEmployeesAction() {
      const data = {
        recurring_order_id: this.directOrder.id,
      };
      console.log(this.directOrder.id);
     
    },

    showEmployeePropositionModal() {
      if (
        this.directOrder.status === -1 &&
        this.directOrdersSalaryPaymentByOrder.find((el) => el.status === 0)
      ) {
        this.$swal({
          title: "Attention!",
          text:
            "Impossible de faire une  proposition. Cette commande résilié à un paiement non payé.·",
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        this.$bvModal.show("modal-new-proposition");
      }
    },

    
    applyModifyPropositionAction() {
      if (this.customerBudget <= this.employee_amount) {
        this.isMakingPropositionLoading = false;
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              text:
                "Le salaire de l'employé doit être toujours strictement inférieur au salaire de l'employé du client",
              variant: "danger",
            },
          },
          {
            position: "top-center",
          }
        );
      } else if (this.taux < 15) {
        this.isMakingPropositionLoading = false;
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              text: "La commission de YLOMI doit être supérieure  ou égale à 15%",
              variant: "danger",
            },
          },
          {
            position: "top-center",
          }
        );
      } else if (this.customerBudget <= 0) {
        this.isMakingPropositionLoading = false;
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              text:
                "Le salaire de l employé du client doit toujours être strictement supérieur à 0",
              variant: "danger",
            },
          },
          {
            position: "top-center",
          }
        );
      } else if (this.employee_amount <= 0) {
        this.isMakingPropositionLoading = false;
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              text:
                "Le salaire de l'employé doit toujours être strictement supérieur à 0",
              variant: "danger",
            },
          },
          {
            position: "top-center",
          }
        );
      } else {
        this.$refs.modifyPropositionForm.validate().then((success) => {
          if (success) {
            this.isMakingPropositionLoading = true;
            if (this.directOrder.search_employee == 1) {
              const comission =
                this.directOrder.offer_type.name === "Offre confort"
                  ? (parseInt(this.changingEmployee.salary) * 10) / 100
                  : parseInt(this.changingEmployee.salary * 15) / 100;
              this.changingEmployee.salary = Math.round(
                parseInt(this.changingEmployee.salary) + comission
              );
            }
            this.changingEmployee.employee_id = this.selectedEmployee.id;
            this.changingEmployee.employee_amount = this.employee_amount;
            this.changingEmployee.ylomi_amount = this.ylomi_amount;
            this.changingEmployee.percentage = this.taux;
            this.modifyPropositionAction({
              payload: this.changingEmployee,
              id: this.directOrder.id,
              proposedEmployeeId: this.currentProposition.id,
            })
              .then(() => {
                this.getDirectOrderAction(this.directOrder.id).then(async (response) => {
                  this.directOrder = response.data;
                  this.directOrder = this.resolveStatusText(this.directOrder);
                  this.$store.commit("orders/SET_DIRECT_ORDER", this.directOrder);
                  this.isMakingPropositionLoading = false;
                  this.$toast(
                    {
                      component: ToastificationContent,
                      props: {
                        title: "Succès",
                        icon: "CheckIcon",
                        text: "Proposition d'employée modifié avec succès.",
                        variant: "success",
                      },
                    },
                    {
                      position: "top-center",
                    }
                  );
                  this.hidePropositionModificationModal();
                  await this.applyGetDirectOrderProposedEmployeesAction();
                  this.$bvModal.show("modal-proposed-employee");
                });
                this.getDirectOrdersAction();
              })
              .catch((error) => {
                this.isMakingPropositionLoading = false;
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: "Error",
                      icon: "AlertTriangleIcon",
                      text: error.response.data.message,
                      variant: "danger",
                    },
                  },
                  {
                    position: "top-center",
                  }
                );
              });
          }
        });
      }
    },

    applyDeleteDirectOrderPropositionAction(propositionId) {
      this.$swal({
        title: "Suppression",
        text: "Êtes-vous sûr de vouloir supprimer cette proposition ?",
        icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: "Non",
        allowOutsideClick: false,
        confirmButtonText: "Oui",
        customClass: {
          confirmButton: "btn btn-warning",
          cancelButton: "btn btn-outline-primary ml-1",
        },
        buttonsStyling: false,
        preConfirm: async () => {
          await this.deleteDirectOrderPropositionAction(propositionId);
          return await this.applyGetDirectOrderProposedEmployeesAction();
        },
      })
        .then((response) => {
          console.log(response);
          if (response.isConfirmed) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "Succès",
                  icon: "CheckIcon",
                  text: "Offre supprimée",
                  variant: "success",
                },
              },
              {
                position: "top-center",
              }
            );
          }
          this.$bvModal.show("modal-proposed-employee");
        })
        .catch((error) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Erreur",
                icon: "AlertTriangleIcon",
                text: error,
                variant: "danger",
              },
            },
            {
              position: "top-center",
            }
          );
        });
    },

    /** ******************************Fin Proposition********************************* */

    modifyOrder(directOrder) {
      this.$store.commit("packages/SET_DIRECT_ORDER", directOrder);
      this.$router.push({
        name: "admin-direct-order-modify",
        params: { id: directOrder.id },
      });
      console.log(directOrder);
    },

    sendPaiementLinkToCustoomer(id, employeeName) {
      window.open(
        `https://wa.me/${this.directOrder.user.phone_number}?text=Nos salutations! \n La date du  paiement de la prestation de votre ${this.directOrder.recurring_service.name} *${employeeName}* étant aujourdhui, merci de cliquer sur lien ci dessous pour effectuer le paiement. *https://ylomi.net/direct/${this.directOrder.id}/payment/${id}*`
      );
    },

    applyGetDirectOrdersSalaryPaymentByOrderAction(id) {
      this.getDirectOrdersSalaryPaymentsByOrderAction(id)
        .then((response) => {
          this.directOrdersSalaryPaymentByOrder = response.data;
        })
        .catch((error) => {
          this.directOrdersSalaryPaymentByOrder = [];
        });
    },

    deployEmployee(item) {
      this.$bvModal.show("modal-deploy-employee");
    },

    applyArchiveDirectOrderAction() {
      this.$refs.archivingOrderForm.validate().then((success) => {
        if (success) {
          this.$swal({
            title: "Archivage",
            text: "Êtes-vous sûr de vouloir archiver cette demande?",
            icon: "warning",
            showCancelButton: true,
            showLoaderOnConfirm: true,
            cancelButtonText: "Non",
            allowOutsideClick: false,
            confirmButtonText: "Oui",
            customClass: {
              confirmButton: "btn btn-warning",
              cancelButton: "btn btn-outline-primary ml-1",
            },
            buttonsStyling: false,
            preConfirm: async () => {
              await this.archiveDirectOrderAction({
                directOrderId: this.directOrder.id,
                payload: this.archivingPayload,
              });
              return (
                (await this.getDirectOrdersAction()) &&
                this.getArchivedDirectOrdersAction()
              );
            },
          })
            .then((response) => {
              if (response.value) {
                this.directOrder = response.value.data;
                this.directOrder = this.resolveStatusText(this.directOrder);
                this.$store.commit("orders/SET_DIRECT_ORDER", this.directOrder),
                  this.$router.push({ name: "admin-orders-recurrent-archived" });
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: "Succès",
                      icon: "CheckIcon",
                      text: "Commande archivée",
                      variant: "success",
                    },
                  },
                  {
                    position: "top-center",
                  }
                );
              }
            })
            .catch((error) => {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: "Erreur",
                    icon: "AlertTriangleIcon",
                    text: error,
                    variant: "danger",
                  },
                },
                {
                  position: "top-center",
                }
              );
            });
        }
      });
    },

    applyConfirmedPlacementCostPaidAction(id) {
      this.$swal({
        title: "Confirmer Frais de Placement",
        text: "Êtes-vous sûr de vouloir valider les frais de placement ?",
        icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: "Non",
        allowOutsideClick: false,
        confirmButtonText: "Oui",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-outline-outline ml-1",
        },
        buttonsStyling: false,
        preConfirm: async () => {
          await this.confirmPlacementCostPaidAction(this.directOrder.id);
          return await this.getDirectOrdersAction();
        },
      })
        .then((response) => {
          if (response.isConfirmed) {
            this.getDirectOrderAction(this.directOrder.id).then(async (response) => {
              this.directOrder = response.data;
              this.directOrder = this.resolveStatusText(this.directOrder);
              this.$store.commit("orders/SET_DIRECT_ORDER", this.directOrder);
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: "Succès",
                    icon: "CheckIcon",
                    text: "Frais de placement confirmé avec succès",
                    variant: "success",
                  },
                },
                {
                  position: "top-center",
                }
              );
              this.getDirectOrdersAction();
            });
          }
        })
        .catch((error) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Erreur",
                icon: "AlertTriangleIcon",
                text: error,
                variant: "danger",
              },
            },
            {
              position: "top-center",
            }
          );
        });
    },

    applyDeleteDirectOrderAction() {
      this.$swal({
        title: "Suppression",
        text: "Êtes-vous sûr de vouloir supprimer cette demande?",
        icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: "Non",
        allowOutsideClick: false,
        confirmButtonText: "Oui",
        customClass: {
          confirmButton: "btn btn-warning",
          cancelButton: "btn btn-outline-primary ml-1",
        },
        buttonsStyling: false,
        preConfirm: async () => {
          await this.deleteDirectOrderAction(this.directOrder.id);
          return await this.getDirectOrdersAction();
        },
      })
        .then((response) => {
          if (response.value) {
            this.$router.push({ name: "admin-orders-recurring" });
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "Succès",
                  icon: "CheckIcon",
                  text: "Demande supprimée",
                  variant: "success",
                },
              },
              {
                position: "top-center",
              }
            );
          }
        })
        .catch((error) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Erreur",
                icon: "AlertTriangleIcon",
                text: error,
                variant: "danger",
              },
            },
            {
              position: "top-center",
            }
          );
        });
    },

    applyResetDirectOrderAction() {
      this.$swal({
        title: "Réinitialisation",
        text: "Êtes-vous sûr de vouloir réinitialiser cette commande?",
        icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: "Non",
        allowOutsideClick: false,
        confirmButtonText: "Oui",
        customClass: {
          confirmButton: "btn btn-warning",
          cancelButton: "btn btn-outline-primary ml-1",
        },
        buttonsStyling: false,
        preConfirm: async () => {
          await this.resetOrderAction(this.directOrder.id);
          // eslint-disable-next-line no-return-await
          return await this.getDirectOrdersAction();
        },
      })
        .then((response) => {
          if (response.value) {
            this.$router.go();
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "Succès",
                  icon: "CheckIcon",
                  text: "Commande réinitialisée",
                  variant: "success",
                },
              },
              {
                position: "top-center",
              }
            );
          }
        })
        .catch((error) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Erreur",
                icon: "AlertTriangleIcon",
                text: error,
                variant: "danger",
              },
            },
            {
              position: "top-center",
            }
          );
        });
    },

    applyDeployEmployeeContractAction() {
      this.$bvModal.hide("modal-proposed-employee");
      this.isDeployEmployeeLoading = true;
      this.$swal
        .fire({
          title: "Déployement d'employé",
          text: "Êtes-vous sûr de vouloir déployer cet employé ?",
          icon: "warning",
          showCancelButton: true,
          showLoaderOnConfirm: true,
          cancelButtonText: "Non",
          allowOutsideClick: false,
          confirmButtonText: "Oui",
          customClass: {
            confirmButton: "btn btn-warning",
            cancelButton: "btn btn-outline-primary ml-1",
          },
          buttonsStyling: false,
          preConfirm: () =>
            this.makeEmployeeDeploymentAction({
              orderId: this.directOrder.id,
              payload: this.deployEmployeePayload,
            })
              .then((response) => {})
              .catch((error) => {
                this.$swal.showValidationMessage(`${error.response.data.message}`);
              }),
        })
        .then((response) => {
          if (response.isConfirmed) {
            this.hideModalDeployEmployee();
            this.getDirectOrderAction(this.directOrder.id).then(async (response) => {
              this.directOrder = response.data;
              this.directOrder = this.resolveStatusText(this.directOrder);
              this.$store.commit("orders/SET_DIRECT_ORDER", this.directOrder);
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: "Succès",
                    icon: "CheckIcon",
                    text: "Employé déployé avec succès",
                    variant: "success",
                  },
                },
                {
                  position: "top-center",
                }
              );
              this.getDirectOrdersAction();
            });
          } else {
            this.hideModalDeployEmployee();
          }
        })
        .catch((error) => {
          this.isDeployEmployeeLoading = false;
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Erreur",
                icon: "AlertTriangleIcon",
                text: error.message,
                variant: "danger",
              },
            },
            {
              position: "top-center",
            }
          );
        });
    },

    applyTerminateEmployeeContractAction() {
      this.$refs.terminateEmployeeContratForm.validate().then((success) => {
        if (success) {
          this.isTerminateEmployeeContratLoading = true;
          this.$swal({
            title: "Résilier le contrat employé",
            text: "Êtes-vous sûr de vouloir résilier ce contrat de l'employé actif ?",
            icon: "warning",
            showCancelButton: true,
            showLoaderOnConfirm: true,
            cancelButtonText: "Non",
            allowOutsideClick: false,
            confirmButtonText: "Oui",
            customClass: {
              confirmButton: "btn btn-warning",
              cancelButton: "btn btn-outline-primary ml-1",
            },
            buttonsStyling: false,
            preConfirm: async () =>
              await this.terminateEmployeeContractAction({
                orderId: this.directOrder.id,
                payload: this.terminateEmployeePayload,
              }),
          })
            .then((response) => {
              this.isTerminateEmployeeContratLoading = false;
              if (response.value) {
                this.directOrder = response.value.data;
                this.directOrder = this.resolveStatusText(this.directOrder);
                this.$store.commit("orders/SET_DIRECT_ORDER", this.directOrder),
                  this.$toast(
                    {
                      component: ToastificationContent,
                      props: {
                        title: "Succès",
                        icon: "CheckIcon",
                        text: response.value.message,
                        variant: "success",
                      },
                    },
                    {
                      position: "top-center",
                    }
                  );
                this.$bvModal.hide("modal-terminate-employee-contrat");
                this.getDirectOrdersAction();
              }
            })
            .catch((error) => {
              this.isTerminateEmployeeContratLoading = false;

              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: "Erreur",
                    icon: "AlertTriangleIcon",
                    text: error.message,
                    variant: "danger",
                  },
                },
                {
                  position: "top-center",
                }
              );
            });
        }
      });
    },

    applyMarkDirectOrderAsFinished() {
      this.$swal({
        title: "Clôturer le dossier",
        text: "Êtes-vous sûr de vouloir clôturer le dossier ?",
        icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: "Non",
        allowOutsideClick: false,
        confirmButtonText: "Oui",
        customClass: {
          confirmButton: "btn btn-warning",
          cancelButton: "btn btn-outline-primary ml-1",
        },
        buttonsStyling: false,
        preConfirm: async () =>
          await this.markDirectOrderAsFinishedAction(this.directOrder.id),
      })
        .then((response) => {
          if (response.isConfirmed) {
            this.getDirectOrderAction(this.directOrder.id).then(async (response) => {
              this.directOrder = response.data;
              this.directOrder = this.resolveStatusText(this.directOrder);
              this.$store.commit("orders/SET_DIRECT_ORDER", this.directOrder);
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: "Succès",
                    icon: "CheckIcon",
                    text: "Contrat clôturé avec succès",
                    variant: "success",
                  },
                },
                {
                  position: "top-center",
                }
              );
              this.getDirectOrdersAction();
            });
          }
        })
        .catch((error) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Erreur",
                icon: "AlertTriangleIcon",
                text: error,
                variant: "danger",
              },
            },
            {
              position: "top-center",
            }
          );
        });
    },

    applySendFeedbackActionRH() {
      this.isFeedbackSending = true;
      if (this.getUser.role.slug === 'super-administrateur') {
        this.payload.type = "RH"
      }
      this.sendFeedbackAction({
        orderId: this.directOrder.id,
        payload: this.payload,
      })
        .then(async (response) => {
          this.isFeedbackSending = false;
          this.hideModalSendFeedback();
          this.getDirectOrderAction(this.directOrder.id).then(async (result) => {
            this.directOrder = result.data;
            this.putRecurringOrdersInStoreAction(this.directOrder);
          });
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Succès",
                icon: "CheckIcon",
                text: response.message,
                variant: "success",
              },
            },
            {
              position: "top-center",
            }
          );
          this.getDirectOrdersAction();
        })
        .catch((error) => {
          this.isFeedbackSending = false;
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Erreur",
                icon: "AlertTriangleIcon",
                text: error.response.data.message,
                variant: "danger",
              },
            },
            {
              position: "top-center",
            }
          );
        });
    },

    resolveOfferStatusVariant(employeeId) {
      if (this.directOrder.actif_employee_id != null) {
        if (employeeId === this.directOrder.actif_employee_id) return "success";
        return "info";
      }
      return "info";
    },
     resolvePropositionStatusVariant: (status) => {
      if (status === -2) return "danger";
      if (status === 0) return "warning";
      if (status === 1) return "info";
      if (status === 2) return "success";
      return "primary";
    },

     resolveStatusPropositionText: (status) => {
      switch (status) {
        case 0:
          return "Effectué";
          break;
        case -2:
          return "Résilié";
          break;
        case -1:
          return "Rejeté";
          break;
        case 1:
          return "Accepté";
          break;
        case 2:
          return "Actif";

        default:
          return "";
      }
     
    },

    resolveOrderStatusVariant: (
      status,
      
    ) => {
      
      if (status === 0) return "warning";
      if (status === 1) return "info";
      if (status === 2) return "Success";
      if (status === -1) return "danger";
      return "primary";
    },

    resolveStatusText(element) {
      if (
        element.status === 0 
      ) {
        element.setStatus = "En attente d'affectation";
        return element;
      }
      
      if (element.status === 1) {
        element.setStatus = "Employé affecté";

        return element;
      }
      if (element.status === 2) {
        element.setStatus = "Actif";
        return element;
      }
      if (element.status === -1) {
        element.setStatus = "Résilié";

        return element;
      }
      
    },
    openWhatsapp(data) {
      window.open(`https://wa.me/${data.user.phone_number}?text=`);
    },
    hideModal() {
      this.$bvModal.hide("modal-new-proposition");
      this.selectedEmployee = "";
      this.payload = {
        salary: "",
        search_employee: "",
        employee_id: "",
      };
    },

    hideModal2() {
      this.$bvModal.hide("modal-new-proposition-2");
      this.payload = {
        salary: "",
        search_employee: "",
        employee_id: "",
      };
    },

    hidePropositionModificationModal() {
      this.$bvModal.hide("modal-modify-proposition");
      this.selectedEmployee = {};
      this.employee_amount = null;
      this.changingEmployee = {
        salary: "",
        search_employee: "",
        employee_id: "",
      };
    },

    hideModalTerminateEmployeeContrat() {
      this.$bvModal.hide("modal-terminate-employee-contrat");
      this.terminateEmployeePayload = {
        date: "",
      };
    },

    hideModalSendFeedback() {
      this.$bvModal.hide("modal-send-feedback");
      this.payload = {
        rh_feedback: "",
      };
    },

    hideModalRecommandation() {
      this.$bvModal.hide("modal-update-order-have-not-recommandation");
      this.updatePayload = {
        employee_salary: "",
        employee_id: "",
      };
    },

    hideModalDeployEmployee() {
      this.$bvModal.hide("modal-deploy-employee");
      this.deployEmployeePayload = {
        employee_deployment_date: "",
      };
    },

     openBusinessEmployeeContract(proposition) {
      console.log(proposition)
      if (proposition.employee_contrat_signature) {
        this.$router.push({
          path: "/preview/pdf",
          query: {
            makeActionOnPage: false,
            actionType: null,
            isSigned: true,
            contratType: "business",
            pdf: proposition.employee_contrat_file_name,
            
          },
        });
      } else {
        this.$router.push({
          path: "/preview/pdf",
          query: {
            makeActionOnPage: true,
            actionType: "signature",
            isSigned: false,
            contratType: "business",
            orderId: this.$route.params.id,
            employeeId: proposition.employee.id,
            pdf: proposition.employee_contrat_file_name,
            
          },
        });
      }
    },

    applyGetTerminatedEmployeesAction() {
      this.isTerminatedEmployees = true;
      this.getTerminatedEmployeesAction(this.directOrder.id)
        .then((response) => {
          this.isTerminatedEmployees = false;
          this.listOfTerminatedEmployees = response.data.data;
        })
        .catch((error) => {
          this.isTerminatedEmployees = false;
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Erreur",
                icon: "AlertTriangleIcon",
                text: error,
                variant: "danger",
              },
            },
            {
              position: "top-center",
            }
          );
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>
<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }

  nav.header-navbar {
    display: none;
  }

  .main-menu {
    display: none;
  }

  .header-navbar-shadow {
    display: none !important;
  }

  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }

  footer.footer {
    display: none;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }

  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style>
.itemActions :hover {
  background: transparent !important;
  color: white;
}
</style>
